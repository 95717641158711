@font-face {
  font-family: 'BebasNeue';
  font-weight: 700;
  font-style: normal;
  src:  url("../assets/fonts/BebasNeueBold.woff2"),
        url("../assets/fonts/BebasNeueBold.woff");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  src:  url("../assets/fonts/Montserrat-SemiBold.woff2"),
        url("../assets/fonts/Montserrat-SemiBold.woff");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src:  url("../assets/fonts/Montserrat-Medium.woff2"),
  url("../assets/fonts/Montserrat-Medium.woff");
}
